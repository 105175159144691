import { useNavigate } from 'react-router';
import React, { useRef, useState, useEffect } from 'react';

import { alpha } from '@mui/material/styles';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import { Box, Card, Checkbox, useTheme, IconButton, Typography } from '@mui/material';

import DLXDriveTag from './DLXDriveTag';
import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';
import { PATH_DLX_DRIVE } from '../../routes/paths';
import DLXDriveFolderIcon from '../../utils/dlx-drive/DLXDriveFolderIcon';
import DLXDriveFolderActionsMenu from './settings/DLXDriveFolderActionsMenu';
import { DLXDriveFolderType, DLXDriveSelectedItem } from '../../@types/dlx-drive/types';
import {
  addItemState,
  setSearchState,
  removeItemState,
  setCardWidthState,
} from '../../redux/slices/dlxDrive';
import {
  FOLDER_ICON_LARGE_SIZE,
  FOLDER_ICON_SMALL_SIZE,
  FOLDER_DEFAULT_ICON_COLOR,
  WIDTH_CORRECTION_LARGE_SCREEN,
  WIDTH_CORRECTION_SMALL_SCREEN,
} from '../../utils/constants/dlxDriveConstants';

// ----------------------------------------------------------------

type DLXDriveFolderCardProps = {
  folderData: DLXDriveFolderType;
  isLargeScreen: boolean;
  isTagsEnabled: boolean;
};

const DLXDriveFolderCard: React.FC<DLXDriveFolderCardProps> = ({
  folderData,
  isLargeScreen,
  isTagsEnabled,
}) => {
  const { t } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isMenuPopoverOpen, setIsMenuPopoverOpen] = useState<boolean>(false);
  const { checkedItems, cardWidth } = useSelector((state) => state.dlxDrive);

  const { id, name, modified, prefix, tags, icon, color, total, actions } = folderData;

  const cardRef = useRef<HTMLDivElement | null>(null);
  const settingsRef = useRef<HTMLElement>(null);
  const isChecked = checkedItems.filter((item) => item.id === id && item.isFolder).length > 0;
  const checkedColor = theme.palette.primary.main;
  const iconSize = isLargeScreen ? `${FOLDER_ICON_LARGE_SIZE}px` : `${FOLDER_ICON_SMALL_SIZE}px`;
  const isLight = theme.palette.mode === 'light';

  const handleFolderClick = () => {
    setSearchState();
    navigate(`${PATH_DLX_DRIVE.root}/${id}/1`);
  };

  const handleWindowResize = () => {
    if (cardRef.current) {
      const newCardWidth = cardRef.current.offsetWidth;

      if (newCardWidth !== cardWidth) {
        setCardWidthState(cardRef.current.offsetWidth);
      }
    }
  };

  const handleCheckboxChange = () => {
    const item: DLXDriveSelectedItem = {
      isFolder: true,
      id,
      name,
      actions,
    };

    if (isChecked) {
      removeItemState(item);
    } else {
      addItemState(item);
    }
  };

  const handleSettingsClick = () => {
    setIsMenuPopoverOpen(true);
  };

  window.addEventListener('resize', handleWindowResize);

  useEffect(() => {
    handleWindowResize();
  }, [cardRef.current]);

  return (
    <>
      <Card
        ref={cardRef}
        sx={{
          p: 1,
          backgroundColor: isChecked ? alpha(checkedColor, 0.1) : undefined,
          border: isChecked ? `1px solid ${alpha(checkedColor, 0.5)}` : '1px solid transparent',
          '&:hover': {
            backgroundColor: isLight
              ? theme.palette.secondary.lighter
              : theme.palette.secondary.darker,
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box>
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={handleFolderClick}
          >
            {/*-----------------------------*/}
            {/* Card view for LARGE screens */}
            {/*-----------------------------*/}

            {isLargeScreen && (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    height: '59px',
                  }}
                >
                  <Box
                    sx={{
                      minWidth: iconSize,
                      minHeight: iconSize,
                      width: iconSize,
                      height: iconSize,
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <DLXDriveFolderIcon
                      icon={icon}
                      color={color.length > 0 ? color : FOLDER_DEFAULT_ICON_COLOR}
                      fontSize="40px"
                    />
                  </Box>

                  <Box
                    sx={{
                      width: cardWidth ? `${cardWidth - WIDTH_CORRECTION_LARGE_SCREEN}px` : '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 0.5,
                    }}
                  >
                    <Typography variant="subtitle1" noWrap>
                      {name}
                    </Typography>

                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: '0%' }}>
                        <Typography variant="body2" color={theme.palette.text.secondary}>
                          {`${t('documentTree.table.modified')}: ${modified.split('T')[0]}`}
                        </Typography>
                      </Box>

                      <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: '0%', overflow: 'hidden' }}>
                        {prefix && (
                          <Typography variant="body2" color={theme.palette.text.secondary} noWrap>
                            {`${t('documentTree.table.prefix')}: ${prefix}`}
                          </Typography>
                        )}
                      </Box>

                      {/* Contains */}
                      <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: '0%' }}>
                        <Typography variant="body2" color={theme.palette.text.secondary}>
                          {t('dlxDrive.pagination.containsItems', { total })}
                        </Typography>
                      </Box>

                      {/* ---empty block--- */}
                      <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: '0%' }} />
                    </Box>
                  </Box>
                </Box>

                {isTagsEnabled && tags.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      textAlign: 'center',
                      flexWrap: 'wrap',
                      gap: 1,
                      my: 1,
                      pl: '5px',
                    }}
                  >
                    {tags.map((tag, index) => (
                      <DLXDriveTag key={`folder-tag-${index}`} tag={tag} />
                    ))}
                  </Box>
                )}
              </>
            )}

            {/*-----------------------------*/}
            {/* Card view for SMALL screens */}
            {/*-----------------------------*/}

            {!isLargeScreen && (
              <Box
                sx={{
                  width: cardWidth ? `${cardWidth - WIDTH_CORRECTION_SMALL_SCREEN}px` : '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      minWidth: iconSize,
                      minHeight: iconSize,
                      width: iconSize,
                      height: iconSize,
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <DLXDriveFolderIcon
                      icon={icon}
                      color={color.length > 0 ? color : FOLDER_DEFAULT_ICON_COLOR}
                      fontSize={iconSize}
                    />
                  </Box>

                  <Typography variant="subtitle1" noWrap>
                    {name}
                  </Typography>
                </Box>

                <Typography variant="body2" color={theme.palette.text.secondary}>
                  {`${t('documentTree.table.modified')}: ${modified.split('T')[0]}`}
                </Typography>

                <Typography variant="body2" color={theme.palette.text.secondary} noWrap>
                  {`${t('documentTree.table.prefix')}: ${prefix || '-'}`}
                </Typography>

                {/* Contains */}
                <Typography variant="body2" color={theme.palette.text.secondary}>
                  {t('dlxDrive.pagination.containsItems', { total })}
                </Typography>

                {isTagsEnabled && tags.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      textAlign: 'center',
                      flexWrap: 'wrap',
                      gap: 1,
                      mt: 1,
                      mb: 0.5,
                    }}
                  >
                    {tags.map((tag, index) => (
                      <DLXDriveTag key={`folder-tag-${index}`} tag={tag} />
                    ))}
                  </Box>
                )}
              </Box>
            )}
          </Box>

          <Box ref={settingsRef}>
            <IconButton size="small" onClick={handleSettingsClick} id="ybFJTu">
              <MoreVertRoundedIcon />
            </IconButton>
          </Box>
        </Box>
      </Card>

      <DLXDriveFolderActionsMenu
        isMenuPopoverOpen={isMenuPopoverOpen}
        setIsMenuPopoverOpen={setIsMenuPopoverOpen}
        anchorEl={settingsRef}
        folderId={id}
        folderColor={color}
        folderIcon={icon}
      />
    </>
  );
};

export default DLXDriveFolderCard;
