import { PagingFilter } from '../shared/pagingFilter';

export class AddressBookFilter extends PagingFilter {
  searchQuery: string | null;

  nameFilterQuery: string | null;

  emailFilterQuery: string | null;

  jobTitleFilterQuery: string | null;

  departmentFilterQuery: string | null;

  RoleFilterQuery: string | null;

  constructor() {
    super();
  }
}
