import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import { RichTreeView } from '@mui/x-tree-view';
import CloseIcon from '@mui/icons-material/Close';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Box, Button, IconButton, Typography } from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import { TreeItem2, TreeItem2Props, TreeItem2SlotProps } from '@mui/x-tree-view/TreeItem2';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import useLocales from '../../../hooks/useLocales';
import Scrollbar from '../../../components/Scrollbar';
import folderService from '../../../services/folderService';
import { FolderTreeProp } from '../../../@types/folders/types';

// ----------------------------------------------------------------

type FolderTreeProps = {
  showDrawer: boolean;
  setShowFolderTree: (isOpen: boolean) => void;
  folderTreeExpandedItems: string[];
  setFolderTreeExpandedItems: (expandedItems: string[]) => void;
};

const FolderTreeView: React.FC<FolderTreeProps> = ({
  showDrawer,
  setShowFolderTree,
  folderTreeExpandedItems,
  setFolderTreeExpandedItems,
}) => {
  const { t } = useLocales();
  const [folderTree, setFolderTree] = useState<FolderTreeProp[]>([]);
  const [disabledItems, setDisabledItems] = useState<string[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const isItemDisabled = (item: FolderTreeProp) => item.disabled;

  const getAllItemsWithChildrenItemIds = () => {
    const itemIds: string[] = [];
    const registerItemId = (item: FolderTreeProp) => {
      if (item.children?.length) {
        itemIds.push(item.id);
        item.children.forEach(registerItemId);
      }
    };

    folderTree.forEach(registerItemId);

    return itemIds;
  };

  const handleItemClick = (itemId: string) => {
    if (!disabledItems.includes(itemId)) {
      navigate(`/folders/${itemId}/1`);
      if (showDrawer) {
        setShowFolderTree(false);
      }
    }
  };

  const handleExpandClick = () => {
    if (folderTreeExpandedItems.length === 0) {
      setFolderTreeExpandedItems(getAllItemsWithChildrenItemIds());
    } else {
      setFolderTreeExpandedItems([]);
    }
  };

  const handleItemExpansionToggle = (
    _: React.SyntheticEvent,
    itemId: string,
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      setFolderTreeExpandedItems([...folderTreeExpandedItems, itemId]);
    } else {
      setFolderTreeExpandedItems(folderTreeExpandedItems.filter((id) => id !== itemId));
    }
  };

  useEffect(() => {
    folderService.getFolderTree().then((data) => {
      const newDisabledItems: string[] = [];

      const registerItemId = (item: FolderTreeProp) => {
        if (item.children?.length) {
          item.children.forEach(registerItemId);
        }

        if (item.disabled) {
          newDisabledItems.push(item.id);
        }
      };

      data.forEach(registerItemId);

      setDisabledItems(newDisabledItems);
      setFolderTree(data);
    });
  }, []);

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomTreeItem = React.forwardRef(
    (props: TreeItem2Props, ref: React.Ref<HTMLLIElement>) => (
      <TreeItem2
        {...props}
        ref={ref}
        label={
          <Box sx={{ width: '100%' }} onClick={() => handleItemClick(props.itemId)}>
            {props.label}
          </Box>
        }
        slotProps={
          {
            checkbox: {
              size: 'small',
              icon: <FolderRoundedIcon fontSize="medium" sx={{ color: '#FFCA28' }} />,
              checkedIcon: <FolderOpenRoundedIcon fontSize="medium" sx={{ color: '#FFCA28' }} />,
            },
          } as TreeItem2SlotProps
        }
      />
    )
  );

  return (
    <Box
      role="presentation"
      sx={{ width: showDrawer ? '400px' : '100%', p: 2, display: 'flex', flexDirection: 'column' }}
    >
      <Scrollbar>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
            mb: 2,
          }}
        >
          <Typography variant="h5">Folder tree</Typography>

          <IconButton size="large" onClick={() => setShowFolderTree(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Button
          variant="contained"
          onClick={handleExpandClick}
          sx={{ width: '100%', mb: 3 }}
          startIcon={
            folderTreeExpandedItems.length === 0 ? <OpenInFullIcon /> : <CloseFullscreenIcon />
          }
        >
          {folderTreeExpandedItems.length === 0 ? 'Expand all' : 'Collapse all'}
        </Button>

        {folderTree.length > 0 && (
          <RichTreeView
            items={folderTree}
            expandedItems={folderTreeExpandedItems}
            selectedItems={id}
            isItemDisabled={isItemDisabled}
            checkboxSelection
            expansionTrigger="iconContainer"
            onItemExpansionToggle={handleItemExpansionToggle}
            slots={{
              item: CustomTreeItem,
              expandIcon: AddBoxOutlinedIcon,
              collapseIcon: IndeterminateCheckBoxOutlinedIcon,
            }}
          />
        )}
      </Scrollbar>
    </Box>
  );
};

export default FolderTreeView;
