import React, { useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, TextField, IconButton, Typography } from '@mui/material';

import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

type FormDefaultSignersProps = {
  emailAddresses: string[];
  setEmailAddresses: (emailAddresses: string[]) => void;
};

const FormDefaultSigners: React.FC<FormDefaultSignersProps> = ({
  emailAddresses,
  setEmailAddresses,
}) => {
  const { t } = useLocales();

  const [newEmailAddress, setNewEmailAddress] = useState<string>('');
  const [isValidEmailAddress, setIsValidEmailAddress] = useState(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmailAddress(event.target.value);
  };

  const handleAddEmailAddress = () => {
    setEmailAddresses([...emailAddresses, newEmailAddress]);
    setNewEmailAddress('');
  };

  const handleDeleteEmailAddress = (index: number) => {
    const newEmailAddresses = [...emailAddresses];

    newEmailAddresses.splice(index, 1);

    setEmailAddresses(newEmailAddresses);
  };

  const validateEmailAddress = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setIsValidEmailAddress(emailPattern.test(newEmailAddress));
  };

  useEffect(() => {
    if (newEmailAddress.length > 0) {
      validateEmailAddress();
    } else {
      setIsValidEmailAddress(true);
    }
  }, [newEmailAddress]);

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <TextField
          size="small"
          fullWidth
          label={`${t('formTemplates.fields.enterEmail')}`}
          value={newEmailAddress}
          onChange={handleInputChange}
          error={isValidEmailAddress}
          helperText={!isValidEmailAddress && t('forms.validationErrorMessage.invalidEmailFormat')}
          id="9xr10ya8"
        />
        <IconButton
          sx={{
            opacity: newEmailAddress.length === 0 || !isValidEmailAddress ? 0.2 : 1,
            mb: !isValidEmailAddress ? '26px' : 0,
          }}
          disabled={newEmailAddress.length === 0 || !isValidEmailAddress}
          onClick={handleAddEmailAddress}
          id="49me5io7"
        >
          <AddIcon color={newEmailAddress === '' || isValidEmailAddress ? 'disabled' : 'primary'} />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 1,
          width: '100%',
          maxHeight: '160px',
          overflow: 'auto',
        }}
      >
        {emailAddresses.length > 0 &&
          emailAddresses.map((email, i) => (
            <Box
              key={`email-address-${email}`}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography noWrap>{email}</Typography>

              <IconButton onClick={() => handleDeleteEmailAddress(i)} id="49me5iot">
                <ClearIcon fontSize="small" color="error" />
              </IconButton>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default FormDefaultSigners;
