import axios from 'src/utils/httpClients/axios';

import { SIFT_API, AUTH0_API } from 'src/config';

// -----------------------------------------------------------

type RangeDate = {
  startDate: string;
  endDate: string;
};

const baseUrl = `${SIFT_API.siftDomain}Action/user/list/count/`;
const identityUrl = `${AUTH0_API.domain}api/companies/tenantActionsCount`;

const head = {
  headers: {
    'x-api-key': SIFT_API.siftApiKey,
  },
};

async function getTenantStatistics(rangeDate: RangeDate) {
  const settings = {
    params: {
      ...rangeDate,
    },
    headers: {
      'Accept-Language': 'en',
    },
  };

  const response = await axios.get(identityUrl, settings);

  const dataToShow = {
    ...response.data.signatureAuth,
    ...response.data.fileStatistics
  };

  return dataToShow;
}

async function getSignatureAuthFilesCounts(email: string | undefined, rangeDate: RangeDate) {
  const settings = {
    params: {
      ...rangeDate,
    },
    ...head,
  };

  const response = await axios.get(`${baseUrl}${email}`, settings);

  const dataToShow = {
    ...response.data.signatureAuth,
    ...response.data.fileStatistics
  };

  return dataToShow;
}

const dashboardB2BAnalyticsService = {
  getSignatureAuthFilesCounts,
  getTenantStatistics,
};

export default dashboardB2BAnalyticsService;
