import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';

import LoadingScreen from '../../components/LoadingScreen';
import { GetSessionData, getSigningSession } from '../../redux/slices/signatureSession';
import { DocumentSignatureType } from '../../@types/documents/enums/documentSignatureTypes';

//------------------------------------

const EparakstsCardCallback = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (sessionId) {
      try {
        const getSessionData = {
          signatureType: DocumentSignatureType.EParaksts,
          comment: '',
        } as GetSessionData;

        getSigningSession(sessionId, getSessionData);
      } catch (error: any) {
        throw error;
      } finally {
        <LoadingScreen />;

        setTimeout(() => {
          const url = location.pathname.replace('eparaksts-mobile/callback', '');
          navigate(url);
        }, 4000);
      }
    }
  }, [sessionId]);

  return <LoadingScreen />;
};

export default EparakstsCardCallback;
