import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
import { LOCALES } from '../../../utils/constants/locales';
import { setIsLoading } from '../../../redux/slices/calendar';
import { CalendarEventType } from '../../../@types/calendar/types';
import ColorPicker from '../../../components/color-picker/ColorPicker';
import { createEventType, updateEventType } from '../../../services/calendarService';

// ----------------------------------------------------------------------

type EditEventTypeProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: (isDialogOpen: boolean) => void;
  eventType: CalendarEventType | null;
  setSelectedEventType: (eventType: CalendarEventType | null) => void;
};

const predefinedColors: string[] = [
  '#000',
  '#9c27b0',
  '#3f51b5',
  '#2196f3',
  '#4caf50',
  '#ff9800',
  '#f5125e',
];

const EditEventTypeDialog: React.FC<EditEventTypeProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  eventType,
  setSelectedEventType,
}) => {
  const { t, currentLang } = useLocales();
  const { isLoading } = useSelector((state) => state.calendar);
  const [color, setColor] = useState<string>('#000');
  const [name, setName] = useState({});

  const handleSaveButtonClick = async () => {
    if (eventType) {
      const model = { id: eventType.id, name: JSON.stringify(name), colorCode: color };

      try {
        await updateEventType(model);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    } else {
      const model = { name: JSON.stringify(name), colorCode: color };

      try {
        await createEventType(model);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }

    handleClose();
  };

  const handleClose = () => {
    setSelectedEventType(null);
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (eventType && isDialogOpen) {
      setColor(eventType.colorCode);
      setName(JSON.parse(eventType.name));
    } else {
      setColor(predefinedColors[0]);

      let newNames = {};

      LOCALES.forEach((locale) => {
        newNames = { ...newNames, [locale]: '-' };
      });

      setName(newNames);
    }
  }, [isDialogOpen]);

  return (
    <Dialog open={isDialogOpen} fullWidth maxWidth="xs" onClose={handleClose}>
      <DialogTitle>
        {eventType
          ? t('calendar.events.editEventType', { eventTypeName: name[currentLang.value] })
          : t('calendar.events.createNewEventType')}
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {LOCALES.map((locale, index) => (
          <TextField
            key={`${locale}-${index}`}
            id={`${locale}-${index}`}
            size="small"
            label={t(`shared.locales.${locale}`)}
            value={name[locale]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName({ ...name, [locale]: event.target.value });
            }}
          />
        ))}

        <DialogContentText>{t('dlxDrive.dialogs.chooseColor')}</DialogContentText>

        <ColorPicker color={color} setColor={setColor} spacing={2} colors={predefinedColors} />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={color.length === 0}
          loading={isLoading}
          variant="contained"
          onClick={handleSaveButtonClick}
          id="yd0sown5"
        >
          {`${t('shared.buttons.save')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditEventTypeDialog;
