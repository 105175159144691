import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  Table,
  Stack,
  Dialog,
  TableRow,
  useTheme,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  DialogTitle,
  CardContent,
  DialogContent,
  TableContainer
} from '@mui/material';

import useLocales from 'src/hooks/useLocales';

import { fDateTime } from 'src/utils/formatTime';

import { ValidationStatus, AdocValidationData } from 'src/@types/documents/validationReport';

import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

type DocumentAdocValidationReportModalProps = {
  data: AdocValidationData;
  handleClose: () => void;
};

type DataRowProps = {
  field: string;
  value?: string;
};

export default function DocumentAdocValidationReportModal({ data, handleClose }: DocumentAdocValidationReportModalProps) {
  const { t } = useLocales();
  const theme = useTheme();

  // eslint-disable-next-line react/no-unstable-nested-components
  const DataRow = ({ field, value }: DataRowProps) => (
      <TableRow>
        <TableCell>
          <Typography variant='subtitle2'>{t(field)}</Typography>
        </TableCell>
        <TableCell sx={{ textAlign: 'end' }}>
          <Typography>{value}</Typography>
        </TableCell>
      </TableRow>
    );

  // eslint-disable-next-line react/no-unstable-nested-components
  const Status = () => {
    switch (data.validationResult) {
      case ValidationStatus.Valid: {
        return (
          <Typography color={theme.palette.success.main} sx={{ verticalAlign: 'center' }}>
            <Iconify mb="-6px" width={24} height={24} icon="eva:checkmark-circle-2-outline" /> {t('documents.validationReport.valid')}
          </Typography>
        );
      }
      case ValidationStatus.NoSignatures: {
        return (
          <Typography color="grey" sx={{ verticalAlign: 'center' }}>
            <Iconify mb="-6px" width={24} height={24} icon="eva:checkmark-circle-2-outline" /> {t('documents.validationReport.noSignatures')}
          </Typography>
        );
      }
      case ValidationStatus.Invalid:
      default: {
        return (
          <Typography color={theme.palette.error.main} sx={{ verticalAlign: 'center' }}>
            <Iconify mb="-6px" width={24} height={24} icon="eva:close-circle-outline" /> {t('documents.validationReport.invalid')}
          </Typography>
        );
      }
    }
  };

  return (
    <Dialog fullWidth open onClose={handleClose}>
      <DialogTitle sx={{ mb: 2 }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography variant='h5'>{t('documents.validationReport.validationReport')}</Typography>
          <Status />

          <IconButton size="small" onClick={handleClose} id="m8mf2ji1">
            <CloseIcon fontSize="small" />
          </IconButton>

        </Stack>
      </DialogTitle>
      <DialogContent>
        <Scrollbar>
          <TableContainer sx={{ mb: 4 }}>
            <Table>
              <TableBody>
                <DataRow field="documents.validationReport.documentName" value={data.name} />
                <DataRow field="documents.validationReport.signaturesCount" value={data.adocSimpleReport?.signaturesCount.toString()} />
                <DataRow field="documents.validationReport.validSignaturesCount" value={data.adocSimpleReport?.validSignaturesCount.toString()} />
                <DataRow field="documents.validationReport.validationTime" value={fDateTime(data.adocSimpleReport?.validationTime || '')} />
                <DataRow field="documents.validationReport.validationPolicy" value={data.adocSimpleReport?.validationPolicy?.policyName} />

                {!!data.adocSimpleReport?.creationReportMetadata && (
                  <>
                    <DataRow field="documents.validationReport.authors" value={data.adocSimpleReport?.creationReportMetadata?.authors} />
                    <DataRow field="documents.validationReport.creationDocumentName" value={data.adocSimpleReport?.creationReportMetadata?.documentName} />
                    <DataRow
                      field="documents.validationReport.documentRegistration"
                      value={data.adocSimpleReport?.creationReportMetadata?.documentRegistration}
                    />
                  </>
                )}

                {!!data.adocSimpleReport?.unsignableReportMetadata && (
                  <>
                    <DataRow field="documents.validationReport.adocType" value={data.adocSimpleReport?.unsignableReportMetadata?.adocType} />
                    <DataRow field="documents.validationReport.documentFormat" value={data.adocSimpleReport?.unsignableReportMetadata?.documentFormat} />
                    <DataRow
                      field="documents.validationReport.documentCreationSoftware"
                      value={data.adocSimpleReport?.unsignableReportMetadata?.documentCreationSoftware}
                    />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!!data.adocStructureErrors && (
            <TableContainer sx={{ mb: 4 }}>
                <Table>
                  <TableBody>
                    <DataRow field="documents.validationReport.adocStructureErrors" value={data.adocStructureErrors} />
                  </TableBody>
                </Table>
              </TableContainer>
          )}
          {!!data.adocSimpleReport?.signatureOrTimestamp?.length && (
            <>
              <Typography variant='h6'>{t('documents.validationReport.signatures')}</Typography>
              {data.adocSimpleReport?.signatureOrTimestamp?.map((item, index) => (
                <Card key={index} sx={{ border: '1px solid #AAAAAA30', mt: 2 }}>
                  <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    <TableContainer sx={{ minWidth: 240 }}>
                      <Table>
                        <TableBody>
                          <DataRow field="documents.validationReport.signedBy" value={item.signature?.signedBy} />
                          <DataRow field="documents.validationReport.signingTime" value={fDateTime(item.signature?.signingTime || '')} />
                          <DataRow field="documents.validationReport.signatureFormat" value={item.signature?.signatureFormat} />
                          <DataRow field="documents.validationReport.signatureLevel" value={item.signature?.signatureLevel?.value} />
                          <DataRow field="documents.validationReport.signatureAuthor" value={item.signatureAdocMetadata?.signatureAuthor} />
                          <DataRow field="documents.validationReport.signatureFileName" value={item.signatureAdocMetadata?.signatureFileName} />
                          <DataRow field="documents.validationReport.signingReason" value={item.signatureAdocMetadata?.signingReason} />
                          <DataRow field='documents.validationReport.certificateExpirationDate' value={fDateTime(item.signature?.certificateExpirationDate || '')} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              ))}
            </>
          )}
        </Scrollbar>
      </DialogContent>
    </Dialog>
  );
}
