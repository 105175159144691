import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../store';
import { HandwrittenSignatureState } from '../../@types/documents/types';

const initialState: HandwrittenSignatureState = {
  fullName: '',
  signatureCanvas: null,
  signatureImg: '',
  signatureImgSize: null,
  signatureImgPosition: null,
  dimensions: null,
  page: 1,
  touched: false,
};

const slice = createSlice({
  name: 'handwrittenSignature',
  initialState,
  reducers: {
    setFullName(state, action) {
      state.fullName = action.payload;
    },

    setSignatureCanvas(state, action) {
      state.signatureCanvas = action.payload;
    },

    setSignatureImg(state, action) {
      state.signatureImg = action.payload;
    },

    setSignatureImgSize(state, action) {
      state.signatureImgSize = action.payload;
    },

    setPosition(state, action) {
      state.signatureImgPosition = action.payload;
    },

    setDimensions(state, action) {
      state.dimensions = action.payload;
    },

    setPage(state, action) {
      state.page = action.payload;
    },

    setTouched(state, action) {
      state.touched = action.payload;
    },

    clearState(state) {
      state.fullName = initialState.fullName;
      state.signatureCanvas = null;
      state.signatureImg = initialState.signatureImg;
      state.signatureImgSize = initialState.signatureImgSize;
      state.signatureImgPosition = initialState.signatureImgPosition;
      state.dimensions = initialState.dimensions;
      state.page = initialState.page;
      state.touched = initialState.touched;
    },
  },
});

export const setSignatureCanvas = (canvas: HTMLCanvasElement) => {
  dispatch(slice.actions.setSignatureCanvas(canvas));
};

export const setSignatureImg = (img: string) => {
  dispatch(slice.actions.setSignatureImg(img));
};

export const setSignatureImgSize = (size: { width: number; height: number }) => {
  dispatch(slice.actions.setSignatureImgSize(size));
};

export const setSignatureImgPosition = (position: { x: number; y: number }) => {
  dispatch(slice.actions.setPosition(position));
};

export const setSignatureDimensions = (dimensions: { width: number; height: number }) => {
  dispatch(slice.actions.setDimensions(dimensions));
};

export const setSignaturePage = (page: number) => {
  dispatch(slice.actions.setPage(page));
};

export const setSignatureTouched = (touched: boolean) => {
  dispatch(slice.actions.setTouched(touched));
};

export const setSignatureFullName = (fullName: string) => {
  dispatch(slice.actions.setFullName(fullName));
};

export const clearSignatureState = () => {
  dispatch(slice.actions.clearState());
};

export default slice.reducer;
