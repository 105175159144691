import * as pdf from 'react-pdf';
import React, { useState, useEffect } from 'react';
import { IDocument } from '@cyntler/react-doc-viewer';
import { Rnd, Position, DraggableData, ResizableDelta } from 'react-rnd';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, useTheme, IconButton, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import useLocales from 'src/hooks/useLocales';

import { useSelector } from '../../redux/store';
import {
  setSignaturePage,
  setSignatureImgSize,
  setSignatureTouched,
  setSignatureImgPosition,
} from '../../redux/slices/handwrittenSignature';

// ----------------------------------------------------------------------

const { Page, pdfjs, Document } = pdf;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type PDFPageProps = {
  pageNumber: number;
  pdfPageWidth: number;
  pdfPageHeight: number;
  annotations: number[][];
};

type DocumentsViewerPDFRendererProps = {
  currentDocument: IDocument;
  wrapperSize: { width: number; height: number };
};

const ResizeIcon = (color: string, hoverColor: string) => (
  <Box>
    <ArrowForwardIosIcon
      fontSize="medium"
      sx={{
        rotate: '45deg',
        color,
        '&:hover': {
          color: hoverColor,
        },
      }}
    />
  </Box>
);

const DocumentsViewerPDFRenderer: React.FC<DocumentsViewerPDFRendererProps> = ({
  currentDocument,
  wrapperSize,
}) => {
  const { t } = useLocales();
  const { signatureImg, dimensions, touched } = useSelector((state) => state.handwrittenSignature);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pdfPages, setPDFPages] = useState<PDFPageProps[]>([]);
  const [signatureSize, setSignatureSize] = useState({
    width: 0,
    height: 0,
  });
  const [signaturePosition, setSignaturePosition] = useState({ x: 100, y: 100 });
  const [scale, setScale] = useState<number>(1);
  const [currentPDFPage, setCurrentPDFPage] = useState<PDFPageProps>({
    pageNumber: -1,
    pdfPageWidth: -1,
    pdfPageHeight: -1,
    annotations: [],
  });
  const theme = useTheme();

  const onDocumentLoadSuccess = async (pdf: any) => {
    const { numPages } = pdf;

    const newPDFPages: PDFPageProps[] = [];

    for (let i = 1; i <= numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });

      const annotations = await page.getAnnotations();

      const newPDFPage: PDFPageProps = {
        pageNumber: i,
        pdfPageWidth: viewport.width,
        pdfPageHeight: viewport.height,
        annotations:
          annotations.length > 0 ? annotations.map((annotation: any) => annotation.rect) : [],
      };

      newPDFPages.push(newPDFPage);
    }

    setNumPages(numPages);
    setPageNumber(1);
    setCurrentPDFPage(newPDFPages[0]);
    setPDFPages(newPDFPages);
  };

  const handleDragStop = (data: DraggableData) => {
    const newPos = {
      x: Math.round(data.x),
      y: Math.round(data.y),
    };

    if (newPos.x !== signaturePosition.x || newPos.y !== signaturePosition.y) {
      setSignaturePosition(newPos);
    }
  };

  const handleResizeStop = (delta: ResizableDelta, position: Position) => {
    const { width, height } = delta;
    setSignatureSize({ width: signatureSize.width + width, height: signatureSize.height + height });

    const { x, y } = position;
    setSignaturePosition({ x, y });
  };

  const handleSignatureTouched = () => {
    if (!touched) {
      setSignatureTouched(true);
    }
  };

  const previousDocument = () => {
    setPageNumber(pageNumber - 1);
  };

  const nextDocument = () => {
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (currentPDFPage && currentPDFPage.pageNumber !== -1) {
      const currentPDFPageWidth = pdfPages[pageNumber - 1].pdfPageWidth;

      if (wrapperSize.width > 0 && currentPDFPageWidth > 0) {
        const newScale = wrapperSize.width / currentPDFPageWidth;

        if (newScale !== scale) {
          setScale(newScale);
        }
      }
    }
  }, [currentPDFPage, wrapperSize]);

  useEffect(() => {
    setCurrentPDFPage(pdfPages[pageNumber - 1]);
    setSignaturePage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (dimensions) {
      setSignatureSize({
        width: (dimensions.width / 5) * scale,
        height: (dimensions.height / 5) * scale,
      });
    }
  }, [dimensions]);

  useEffect(() => {
    setSignatureImgPosition({ x: signaturePosition.x / scale, y: signaturePosition.y / scale });
  }, [signaturePosition]);

  useEffect(() => {
    setSignatureImgSize({
      width: signatureSize.width / scale,
      height: signatureSize.height / scale,
    });
  }, [signatureSize]);

  useEffect(() => {
    setSignaturePosition({
      x: (currentPDFPage.pdfPageWidth / 6) * scale,
      y: (currentPDFPage.pdfPageHeight / 2) * scale,
    });
  }, [signatureImg]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'space-between',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 2 }}>
        <IconButton
          id="previous-page-button"
          disabled={pageNumber === 1}
          size="small"
          onClick={previousDocument}
        >
          <ChevronLeftIcon fontSize="inherit" />
        </IconButton>

        <Typography sx={{ textAlign: 'center' }} noWrap>
          {t('forms.documentNavigation.page')} {pageNumber} {t('forms.documentNavigation.of')}{' '}
          {numPages}
        </Typography>

        <IconButton
          id="next-page-button"
          disabled={pageNumber === numPages}
          onClick={nextDocument}
          size="small"
        >
          <ChevronRightIcon fontSize="inherit" />
        </IconButton>
      </Box>

      {currentDocument && wrapperSize.width > 0 && (
        <Box
          sx={{
            position: 'relative',
            width:
              currentPDFPage && currentPDFPage.pdfPageWidth
                ? currentPDFPage.pdfPageWidth * scale
                : 600,
            height:
              currentPDFPage && currentPDFPage.pdfPageHeight
                ? currentPDFPage.pdfPageHeight * scale
                : 849,
            zIndex: 1001,
          }}
        >
          <Document
            file={currentDocument.uri}
            onLoadSuccess={onDocumentLoadSuccess}
            externalLinkTarget="_blank"
          >
            <Page pageNumber={pageNumber} width={wrapperSize.width} />
          </Document>

          {signatureImg && (
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: 9002,
              }}
            >
              <Rnd
                size={{ width: signatureSize.width, height: signatureSize.height }}
                position={{
                  x: signaturePosition.x,
                  y: signaturePosition.y,
                }}
                bounds="parent"
                lockAspectRatio
                enableResizing={{ bottomRight: true }}
                onDragStop={(_, d) => {
                  handleDragStop(d);
                  handleSignatureTouched();
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                  handleResizeStop(delta, position);
                  handleSignatureTouched();
                }}
                resizeHandleComponent={{
                  bottomRight: ResizeIcon(theme.palette.secondary.dark, theme.palette.primary.main),
                }}
                style={{
                  border: !touched
                    ? '1px solid rgba(232, 104, 62, 1)'
                    : '1px dotted rgba(0, 0, 0, .2)',
                  backgroundColor: !touched ? 'rgba(232, 104, 62, .2)' : '',
                }}
                id="mco9ookk"
              >
                <Box
                  sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                />

                <img src={signatureImg} style={{ objectFit: 'scale-down' }} alt="Signature" />
              </Rnd>
            </Box>
          )}
        </Box>
      )}

      {/* ---- TODO: Mark annotations ----*/}

      {/* {currentPDFPage && */}
      {/*  currentPDFPage.annotations.length > 0 && */}
      {/*  currentPDFPage.annotations.map((annotation, index) => ( */}
      {/*    <Box */}
      {/*      sx={{ */}
      {/*        width: `${(annotation[2] - annotation[0]) * scale}px`, */}
      {/*        height: `${(annotation[3] - annotation[1]) * scale}px`, */}
      {/*        backgroundColor: 'pink', */}
      {/*        position: 'absolute', */}
      {/*        bottom: `${annotation[1] * scale}px`, */}
      {/*        left: `${annotation[0] * scale}px`, */}
      {/*        zIndex: 100, */}
      {/*        opacity: 0.2, */}
      {/*      }} */}
      {/*    /> */}
      {/*  ))} */}
    </Box>
  );
};

export default DocumentsViewerPDFRenderer;
